<template>
  <div :style="{maxWidth: '500px', display: 'flex'}">
    <v-card v-if="showConfig"
      :style="{ display: 'flex', flexDirection: 'column', maxWidth: '500px', minWidth: '400px', maxHeight: '100vh', zIndex: '5' }">
      <v-card-text class="scroll" :style="{ overflow: 'visible auto !important', flexGrow: 1 }">
        <v-row dense>
          <v-col>
            <v-btn outlined block @click="handleShowPosition">
              {{ showPosition ? 'Hide Position' : 'Show Position' }}
              <v-icon right dark>mdi-code-braces</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn class="ma-0" color="primary" @click="copyToClipboard" outlined block>
              Copy (Ctrl + C)
              <v-icon right>mdi-content-copy</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense v-if="showPosition">
          <div :style="{ backgroundColor: '#ebebeb', borderRadius: '4px' }" class="pa-2 mt-2">
            <pre :style="{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }">{{ JSON.stringify(position) }}</pre>
          </div>
        </v-row>
        <v-row class="ml-2" dense>
          <v-switch v-model="drawMode" label="Keyboard/Mouse Controls"></v-switch>
        </v-row>

        <v-row dense v-if="drawMode">
          <v-col>
            <v-subheader class="pl-0"> Position Grid <span class="ma-5 control-help-text">Mouse
                Click</span></v-subheader>
          </v-col>
        </v-row>

        <v-row dense v-if="drawMode">
          <v-col>
            <v-subheader class="pl-0"> Look Around <span class="ma-5 control-help-text">Left Alt + Mouse
                Drag</span></v-subheader>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-subheader class="pl-0">X Position</v-subheader>
            <v-slider v-model="yaw" class="align-center" :max="180" :min="-180" :step="0.1" hide-details>
              <template v-slot:append>
                <v-text-field v-model="yaw" class="mt-0 pt-0" hide-details single-line type="number"
                  style="width: 60px"></v-text-field>
                <v-btn icon @click="yaw += 0.1"><v-icon>mdi-plus</v-icon></v-btn>
                <v-btn icon @click="yaw -= 0.1"><v-icon>mdi-minus</v-icon></v-btn>
              </template>
            </v-slider>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-subheader class="pl-0">Y Position</v-subheader>
            <v-slider v-model="pitch" class="align-center" :max="180" :min="-180" :step="0.1" hide-details>
              <template v-slot:append>
                <v-text-field v-model="pitch" class="mt-0 pt-0" hide-details single-line type="number"
                  style="width: 60px"></v-text-field>
                <v-btn icon @click="pitch += 0.1"><v-icon>mdi-plus</v-icon></v-btn>
                <v-btn icon @click="pitch -= 0.1"><v-icon>mdi-minus</v-icon></v-btn>
              </template>
            </v-slider>
          </v-col>
        </v-row>


        <v-row dense>
          <v-col>
            <v-subheader class="pl-0">Width <span v-if="drawMode" class="ma-5 control-help-text">Shift + Left
                Click Drag</span></v-subheader>
            <v-slider v-model="widthSlider" class="align-center" :max="2000" :min="5" hide-details
              @input="(value) => handleSliderChange(value, 'width') ">
              <template v-slot:append>
                <v-text-field v-model="width" class="mt-0 pt-0" hide-details single-line
                  style="width: 60px"></v-text-field>
                <v-btn icon @click="width += 1"><v-icon>mdi-plus</v-icon></v-btn>
                <v-btn icon @click="width -= 1"><v-icon>mdi-minus</v-icon></v-btn>
              </template>
            </v-slider>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-subheader class="pl-0">Height <span v-if="drawMode" class="ma-5 control-help-text"
                variant="outlined">Ctrl
                +
                Left
                Click Drag</span></v-subheader>
            <v-slider v-model="heightSlider" class="align-center" :max="2000" :min="5" hide-details
              @input="(value) => handleSliderChange(value, 'height') ">
              <template v-slot:append>
                <v-text-field v-model="height" class="mt-0 pt-0" hide-details single-line
                  style="width: 60px"></v-text-field>
                <v-btn icon @click="height += 1"><v-icon>mdi-plus</v-icon></v-btn>
                <v-btn icon @click="height -= 1"><v-icon>mdi-minus</v-icon></v-btn>
              </template>
            </v-slider>
          </v-col>
        </v-row>
        <v-row dense v-if="drawMode">
          <v-col :style="{ flexGrow: 1 }">
            <v-text-field v-model="transformMultiplier" label="Width/Height Multiplier" type="number" filled>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-subheader class="pl-0">X Rotation <span v-if="drawMode" class="ma-5 control-help-text">Shift + Mouse
                Wheel</span></v-subheader>
            <v-slider v-model="rotXSlider" class="align-center" :max="90" :min="-90" hide-details
              @input="(value) => handleSliderChange(value, 'rotX') ">
              <template v-slot:append>
                <v-text-field v-model="rotX" class="mt-0 pt-0" hide-details single-line
                  style="width: 60px"></v-text-field>
                <v-btn icon @click="() => plusRotationValue('rotX') "><v-icon>mdi-plus</v-icon></v-btn>
                <v-btn icon @click="() => minusRotationValue('rotX') "><v-icon>mdi-minus</v-icon></v-btn>
              </template>
            </v-slider>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-subheader class="pl-0">Y Rotation <span v-if="drawMode" class="ma-5 control-help-text">Ctrl + Mouse
                Wheel</span></v-subheader>
            <v-slider v-model="rotYSlider" class="align-center" :max="90" :min="-90" hide-details
              @input="(value) => handleSliderChange(value, 'rotY')">
              <template v-slot:append>
                <v-text-field v-model="rotY" class="mt-0 pt-0" hide-details single-line
                  style="width: 60px"></v-text-field>
                <v-btn icon @click="() => plusRotationValue('rotY') "><v-icon>mdi-plus</v-icon></v-btn>
                <v-btn icon @click="() => minusRotationValue('rotY') "><v-icon>mdi-minus</v-icon></v-btn>
              </template>
            </v-slider>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-subheader class="pl-0">Z Rotation <span v-if="drawMode" class="ma-5 control-help-text">Shift + Ctrl +
                Mouse
                Wheel</span></v-subheader>
            <v-slider v-model="rotZSlider" class="align-center" :max="180" :min="-180" hide-details
              @input="(value) => handleSliderChange(value, 'rotZ') ">
              <template v-slot:append>
                <v-text-field v-model="rotZ" class="mt-0 pt-0" hide-details single-line
                  style="width: 60px"></v-text-field>
                <v-btn icon @click="() => plusRotationValue('rotZ')"><v-icon>mdi-plus</v-icon></v-btn>
                <v-btn icon @click="() => minusRotationValue('rotZ') "><v-icon>mdi-minus</v-icon></v-btn>
              </template>
            </v-slider>
          </v-col>
        </v-row>
        <v-row v-if="drawMode" dense :style="{ display: 'flex' }" class="pl-1">
          <v-col :style="{ flexGrow: 1 }" class="pl-1">
            <v-text-field v-model="rotationIncrement" label="Rotation Increment (degrees)" type="number" filled>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-btn depressed :style="{zIndex: 6, backgroundColor: 'white'}" @click="handleShowConfig">

      <v-icon v-if="showConfig">mdi-arrow-collapse-left</v-icon>
      <v-icon v-if="!showConfig">mdi-arrow-expand-right</v-icon>

    </v-btn>
  </div>

</template>

<script>


import Marzipano from 'marzipano'

let startMouseX = 0
let startMouseY = 0
let leftAlt = false
let leftCtrl = false

export default {
  name: "MarzipanoRectConfigurator",
  props: ["scene"],
  data: () => ({
    yaw: 0.0,
    pitch: 0.0,
    width: 100.0,
    height: 100.0,
    rotX: 0.0,
    rotY: 0.0,
    rotZ: 0.0,
    firstPos: undefined,
    secondPos: undefined,
    drawMode: false,
    transformMultiplier: 0.5, 
    rotationIncrement: 5.0,
    showPosition: false,
    showConfig: true,

    widthSlider: 100,
    heightSlider: 100,
    rotXSlider: 0,
    rotYSlider: 0,
    rotZSlider: 0,
  }),
  components: {},
  mounted() {

    document.getElementsByName("marzipano-room")[0].addEventListener('wheel', (event) => this.handleMouseWheel(event))
    document.getElementsByName("marzipano-room")[0].addEventListener('mousedown', (event) => this.handleMouseDown(event))
    document.getElementsByName("marzipano-room")[0].addEventListener('mousemove', (event) => this.handleMouseMove(event))
    document.getElementsByName("marzipano-room")[0].addEventListener('click', (event) => this.handleClick(event))

    window.addEventListener('keydown', (event) => this.handleKeydown(event) )
    window.addEventListener('keyup', (event) => this.handleKeyUp(event))

  },
  methods: {
  plusRotationValue(rotation) {
    let total = this[rotation] + parseFloat(this.rotationIncrement)
    this[rotation] = parseFloat(total.toFixed(1))
  },
  minusRotationValue(rotation) {
    let total = this[rotation] - parseFloat(this.rotationIncrement)
    this[rotation] = parseFloat(total.toFixed(1))
  },
  handleSliderChange(sliderValue, config) { 
      this[config] = sliderValue
    },
  handleConfigChange(configValue, slider) {
      let newSliderValue = Math.round(configValue)
      this[slider] = newSliderValue
    },
  handleShowConfig() {
    this.showConfig = !this.showConfig
  },
  handleShowPosition() {
    this.showPosition = !this.showPosition
  },

  async copyToClipboard() {
    await navigator.clipboard.writeText(JSON.stringify(this.position))
  },
  async handleKeydown(event) {
    if(!this.drawMode) return;

    if(!leftAlt && event.code === 'AltLeft') {
      leftAlt = true
      this.scene.viewer().controls().enable()
    }

    if (!leftCtrl) {
      leftCtrl = true
    }
  
    if(leftCtrl && event.code === 'KeyC'){
      await this.copyToClipboard()
    }
  },

  handleKeyUp() {
    if (!this.drawMode) return;

    if (leftAlt) {
      leftAlt = false
      this.scene.viewer().controls().disable()
    }

    if (leftCtrl) {
      leftCtrl = false
    }
  },

  handleMouseDown(event) {
    if (!this.drawMode) return;

    startMouseX = event.clientX;
    startMouseY = event.clientY;
    
  },

  handleMouseMove(event) {
    if (!this.drawMode) return;
    if (!event.ctrlKey && !event.shiftKey) return;

    let transformMultiplier = parseFloat(this.transformMultiplier)

    const currentMouseX = event.clientX;
    const currentMouseY = event.clientY;

  
    const yaw = Marzipano.util.degToRad(this.yaw)
    const pitch = Marzipano.util.degToRad(this.pitch)

    const coordinates = { yaw: yaw, pitch: pitch }
    const center = this.scene.view().coordinatesToScreen(coordinates)


    const centerX = Math.round(center.x)
    const centerY = Math.round(center.y)

    let mouseDistance = Math.sqrt((currentMouseX - startMouseX) ** 2 + (currentMouseY - startMouseY) ** 2) * transformMultiplier

    const centerToStartDistance = Math.sqrt(Math.pow((startMouseX - centerX), 2) + Math.pow((startMouseY - centerY), 2))
    const centerToEndDistance = Math.sqrt(Math.pow((currentMouseX - centerX), 2) + Math.pow((currentMouseY - centerY), 2))

    if (event.which === 1) {
      if (event.ctrlKey) {
        let height = 0
        if (centerToStartDistance <= centerToEndDistance) {
          height = this.height + mouseDistance
        } else {
          height = this.height - mouseDistance
        }
        this.height = parseFloat(height.toFixed(1))
      }

      if (event.shiftKey) {
        let width = 0
        if (centerToStartDistance <= centerToEndDistance) {
          width = this.width + mouseDistance
        } else {
          width = this.width - mouseDistance
        }
        this.width = parseFloat(width.toFixed(1))

      }
    }

  },

  handleMouseWheel(event) {
    if (!this.drawMode) return;
    if (!event.ctrlKey && !event.shiftKey) return;

    let rotationIncrement = parseFloat(this.rotationIncrement)

    const direction = event.deltaY * -0.01
    
    if (event.shiftKey && event.ctrlKey) {
      let rotation = 0
      if (direction < 0) {
        rotation = this.rotZ - rotationIncrement;
      } else {
        rotation = this.rotZ + rotationIncrement;
      }
      this.rotZ = parseFloat(rotation.toFixed(1))

    } else if (event.shiftKey) {
      let rotation = 0

      if (direction < 0) {
        rotation = this.rotX - rotationIncrement;
      } else {
        rotation = this.rotX + rotationIncrement;
      }
  
      this.rotX = parseFloat(rotation.toFixed(1))


    } else if (event.ctrlKey) {
      let rotation = 0

      if (direction < 0) {
        rotation = this.rotY - rotationIncrement;
      } else {
        rotation = this.rotY + rotationIncrement;
      }

      this.rotY = parseFloat(rotation.toFixed(1))

    }
  },


  handleClick(event) {
    if (this.drawMode && !event.shiftKey && !event.ctrlKey && !event.altKey) {

      const screenCoordinates = { x: event.clientX, y: event.clientY }

      let result = this.scene.view().screenToCoordinates(screenCoordinates)
      this.yaw = Marzipano.util.radToDeg(result.yaw)
      this.pitch = Marzipano.util.radToDeg(result.pitch)
    }
  },
},
  computed: {
    changeData() {
      let data = {
        yaw: (this.yaw * Math.PI) / 180.0,
        pitch: (this.pitch * Math.PI) / 180.0,
        width: this.width,
        height: this.height,
        rotX: this.rotX,
        rotY: this.rotY,
        rotZ: this.rotZ,
      };
      return data
    },

    position() {
      let data = {
        yaw: (this.yaw * Math.PI) / 180.0,
        pitch: (this.pitch * Math.PI) / 180.0,
        width: String(this.width) + 'px',
        height: String(this.height) + 'px',
        rotX: this.rotX,
        rotY: this.rotY,
        rotZ: this.rotZ,
      };
      return data
    }


  },
  watch: {
    width(value) {
      this.widthSlider = Math.round(value)
    },
    height(value) {
      this.heightSlider = Math.round(value)
    },
    rotX(value) {
      this.rotXSlider = Math.round(value)
    },
    rotY(value) {
      this.rotYSlider = Math.round(value)
    },
    rotZ(value) {
      this.rotZSlider = Math.round(value)
    },

    changeData() {
      this.$emit("changed", this.changeData);
    },

    drawMode(value) {
      if (value === true) {
        this.scene.viewer().controls().disable()
      } else {
        this.scene.viewer().controls().enable()
      }
    }


  },
}
 
</script>

<style scoped>

.scroll::-webkit-scrollbar {
  width: 12px;
}

.scroll::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgb(219, 219, 219);
}

.scroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #c4c4c4;
}

.scroll::-webkit-scrollbar-thumb:window-inactive {
  background: #c4c4c4;
}

.hotspot-bg {
  background-color: rgba(255, 0, 0, 0.5);
}

.control-help-text{
  border: 1px #c4c4c4 solid;
  border-radius: 5px;
  padding: 5px 8px 5px 8px;
}
</style>